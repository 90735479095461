/* eslint-disable no-undef, react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import { css } from 'react-emotion';
import { Helmet } from 'react-helmet';
import { PaperBox, Flex } from '../components/Layout';
import PageWrapper from '../components/PageWrapper';
import colors from '../utils/colors';
import BackgroundImage from 'gatsby-background-image';

import gooseberry from '../assets/images/gooseberry-black.png';
import wavesLeft from '../assets/images/waves-left.png';
import wavesRight from '../assets/images/waves-right.png';

const imgStyle = css`
  border-radius: 5px;
`;

const mainName = css`
  text-align: left;
  font-size: 1.2em;
  font-weight: bold;
`;

const top = css`
  padding-top: 40px;
  text-align: center;
  @media screen and (max-width: 600px) {
    padding-top: 70px;
    }
`;

const bottom = css`
  padding-bottom: 200px;
  @media screen and (max-width: 600px) {
      padding-bottom: 100px;
  }
`;

const padding = css`
  padding-top: 50px;
  @media screen and (max-width: 600px) {
      padding-top: 100px;
    }
`;

const welcomeText = css`
  padding-bottom: 10px;
  padding-top: 0px;
`;

const wavesLeftStyle = css`
  float: left;
  width: 16vw;
`;

const wavesRightStyle = css`
  float: right;
  width: 16vw;
`;

const gooseberryLeftStyle = css`
  width: 50px;
  transform: rotate(120deg);
  margin-bottom: 30px;
`;

const gooseberryRightStyle = css`
  width: 60px;
  transform: rotate(70deg);
`;

const Story = ({ data }) => {
  const mapData = data.map.childImageSharp;
  return (
    <PageWrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Story - Beacons</title>
      </Helmet>

      <BackgroundImage
        Tag="section"
        className={imgStyle}
        fluid={mapData.fluid}
        backgroundColor={`#FFFFFF`}
        padding="20px"
        margin="40px"
      >
        <Flex>
          <PaperBox 
            width={[1, '80%', '60%']}
            ml="auto"
            mr="auto"
            mt="50px"
            px={[3, 3, 5]}
            py={[3, 3, 3]}
            color={colors.secondary}
            textAlign="center"
          >
            <h1 className={top}>The Story of the<br/>Sea Gooseberries</h1>
            <p>Sea Gooseberries are our magical ancient ancestors.</p>
            <p>They cross the oceans with messages from the past and songs for the future.</p>
            <p>Each year they arrive hoping we will recognise their magic and hear their songs.</p>
            <p>In the old times we held joyful Gathering ceremonies to celebrate their arrival.</p>
            <p>Everyone would come together and celebrate their connections to each other and the natural world. We would enjoy fire and feasting.</p>
            <p>Now everyone has forgotten the Sea Gooseberries.</p>
            <p>The balance between us and the rest of the eco-system is upset.</p>
            <p>The magic is fading and soon will be lost altogether.</p>
            <p>
              <img src={gooseberry} alt="Sea Gooseberry" className={gooseberryLeftStyle}/>
              <img src={gooseberry} alt="Sea Gooseberry" width="100"/>
              <img src={gooseberry} alt="Sea Gooseberry" className={gooseberryRightStyle}/>
            </p>
            <p>We Oracles believe there is still a chance to restore order and celebrate once more.</p>
            <p>The last surviving two hundred Sea Gooseberries have returned.</p>
            <p>Weak and separated by the winter storms, they drift on the wind, lost and alone.</p>
            <p>They leave songs and messages of wisdom in secret hiding places.</p>
            <p>They are not strong enough to connect with each other any more, but their magic only works if they are together.</p>
            <p>We need you to rescue them - join the Quest to find their messages and piece together their song fragments.</p>
            <p>Register any you find so they know you care.</p>
            <p>Perhaps you will even shelter a Sea Gooseberry in your home until the Gathering.</p>
            <p>On 19th December we will bring the Sea Gooseberries back together on the beach, to share in our collective energy and re-ignite their magic. The balance will be restored by your presence.</p>
            <img src={wavesLeft} alt="wave" className={wavesLeftStyle}/>
            <img src={wavesRight} alt="wave" className={wavesRightStyle}/>
            <p className={bottom}>ALL ARE WELCOME. JOIN THE QUEST.</p>
          </PaperBox>
        </Flex>
      </BackgroundImage>
    </PageWrapper>
  );
};

export const query = graphql`
query {
  map: file(relativePath: { eq: "map.jpeg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  allContentJson {
		edges {
			node {
				index {
					title
					aboutText
				}
      }
    }
  }
}
`;

export default Story;
